import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchFeed, clearFeedData } from '../store/feedSlice';
import Feed from '../components/Feed';
import LoadingSpinner from '../components/LoadingSpinner';
import AuthenticationSpinner from '../components/AuthenticationSpinner';
import PageHelmet from '../components/PageHelmet';
import styles from '../styles/FeedPage.module.css';

const FeedPage = () => {
    const dispatch = useDispatch();
    const { data, loading: excerptsLoading, error } = useSelector((state) => state.feed);
    const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const showsUuid = queryParams.get('shows_uuid');
    const personsUuid = queryParams.get('persons_uuid');
    const tagsUuid = queryParams.get('tags_uuid');

    useEffect(() => {
        if (!authLoading) {
            if (isAuthenticated) {
                const filter = showsUuid ? { type: 'shows_uuid', uuid: showsUuid } :
                               personsUuid ? { type: 'persons_uuid', uuid: personsUuid } :
                               tagsUuid ? { type: 'tags_uuid', uuid: tagsUuid } :
                               null;
                dispatch(fetchFeed(filter));
            } else {
                localStorage.setItem('redirectAfterAuth', location.pathname + location.search);
                navigate('/auth?from=' + encodeURIComponent(location.pathname + location.search));
            }
        }

        return () => {
            dispatch(clearFeedData());
        };
    }, [dispatch, isAuthenticated, authLoading, showsUuid, personsUuid, navigate, location]);

    const handleRefresh = () => {
        window.location.reload();
    };

    if (authLoading) {
        return <AuthenticationSpinner />;
    }

    if (!isAuthenticated) {
        return null;
    }

    if (excerptsLoading) {
        return <LoadingSpinner text="Loading feed..." />;
    }
  
    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <PageHelmet title="Feed" />
            <div className={styles.feedPageContainer}>
                <Feed excerpts={data?.excerpts} onRefresh={handleRefresh} />
            </div>
        </>
    );
};

export default FeedPage;
